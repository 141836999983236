import React, { useState, useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import { ArrowUpRight, ChevronDown, ExternalLink, Github, Code } from 'lucide-react';

// Import images (assuming the same imports as before)
import HelmetPro from '../assets/helmetpro.jpeg';
import BingoAiImage from '../assets/gobingo.png';
import Digi8 from '../assets/digi8.jpeg';
import SuboMoTo from '../assets/subomoto.jpeg';
import InsightfulAiImage from '../assets/insightai.jpeg';
import ZelinCafe from "../assets/zelin.jpeg";
import Iland from "../assets/Iland.jpeg";

const projects = [
  {
    title: "I-Land Residence Sucat Website",
    category: "Web Portfolio",
    description: "Developed a sophisticated mobile-first website for I-Land Residence Sucat condominium, creating an immersive digital showcase for potential residents and investors. The responsive design prioritizes the mobile experience while seamlessly adapting to larger screens for comprehensive property exploration.",
    image: Iland,
    tags: ["NextJS", "TailwindCSS", "Vercel", "Supabase"],
    link: "https://www.ilandresidencesucat.com/"
  },
  {
    title: "Zelin Cafe Website + Web P.O.S",
    category: "Web App and ERP System",
    description: "Developed a comprehensive digital solution for Zelin Cafe featuring a mobile-first website and integrated web point of sale (POS) system. The responsive design ensures seamless user experience across all devices while prioritizing mobile interactions to meet modern customer expectations.",
    image: ZelinCafe,
    tags: ["NextJS", "TailwindCSS", "Vercel", "Supabase"],
    link: "https://zelincoffee-capstone.vercel.app/"
  },
  {
    title: "HelmetPro Website and Internal ERP System",
    category: "Web App and ERP System",
    description: "This project aims to develop a modern Mobile First View Website and a Client-Specific Machine Monitoring and Earnings Dashboard, providing businesses with a seamless way to track and manage their machine operations",
    image: HelmetPro,
    tags: ["NextJS", "TailwindCSS", "Vercel", "Supabase"],
    link: "https://www.helmetprosolutions.com/"
  },
  {
    title: "Digi8 Studio Web Portfolio",
    category: "Web Portfolio",
    description: "Designed and Developed a mobile responsive modern Web Portfolio for Digi-8 Studios",
    image: Digi8,
    tags: ["NextJS", "TailwindCSS", "Vercel"],
    link: "https://www.digi8studios.com/"
  },
  {
    title: "GoBingo AI Telegram Chatbot and Internal CRM system",
    category: "AI Chatbot",
    description: "An AI-powered chatbot and CRM system that streamlines document processing and automation. It also provides GoBingo with a seamless way to track and manage their customer data",
    image: BingoAiImage,
    tags: ["Next.js", "Hugging Face", "AI"],
    link: "https://github.com/CSaguinsin/GoBingoCRM"
  },
  {
    title: "Insightful AI",
    category: "AI Web Tool",
    description: "A tool that enhances decision-making by providing context-aware answers to user queries.",
    image: InsightfulAiImage,
    tags: ["Next.js", "Gemini AI", "Tailwind"],
    link: "/works/insightfulai"
  },
  {
    title: "SuboMoTo",
    category: "AI Web Tool",
    description: "A modern mobile responsive web application for finding restaurants here in metro manila",
    image: SuboMoTo,
    tags: ["Next.js", "Gemini AI", "TailwindCSS", "Vercel"],
    link: "/works/insightfulai"
  },
];

// Tag icons mapping
const getTagIcon = (tag) => {
  switch(tag.toLowerCase()) {
    case 'nextjs':
    case 'next.js':
      return <Code size={14} />;
    case 'vercel':
      return <ArrowUpRight size={14} />;
    case 'github':
      return <Github size={14} />;
    default:
      return null;
  }
};

const Works = () => {
  const [activeCategory, setActiveCategory] = useState('All');
  const [hoveredProject, setHoveredProject] = useState(null);
  const [expandedProject, setExpandedProject] = useState(null);
  const projectsRef = useRef(null);
  const isInView = useInView(projectsRef, { once: false, amount: 0.1 });
  
  // Get unique categories
  const categories = ['All', ...Array.from(new Set(projects.map(p => p.category)))];
  
  // Filter projects by active category
  const filteredProjects = activeCategory === 'All' 
    ? projects 
    : projects.filter(p => p.category === activeCategory);

  return (
    <section id="projects" className="relative py-12 sm:py-16 md:py-24 bg-black overflow-hidden">
      {/* Subtle background patterns and gradients */}
      <div className="absolute inset-0 bg-[radial-gradient(circle_at_40%_40%,rgba(75,0,130,0.08),transparent_60%)]"></div>
      <div className="absolute inset-0 bg-[radial-gradient(circle_at_80%_80%,rgba(59,130,246,0.08),transparent_60%)]"></div>
      <div className="absolute inset-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#4f4f4f2e_1px,transparent_1px)] bg-[size:14px_24px]"></div>
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        {/* Section header with floating elements */}
        <div className="relative mb-10 sm:mb-16 text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="relative z-10"
          >
            <div className="inline-flex items-center justify-center mb-3">
              <span className="w-8 sm:w-12 h-px bg-gradient-to-r from-transparent via-purple-500 to-transparent"></span>
              <span className="mx-2 sm:mx-3 text-purple-400 text-xs sm:text-sm font-medium tracking-wider uppercase">Portfolio</span>
              <span className="w-8 sm:w-12 h-px bg-gradient-to-r from-transparent via-purple-500 to-transparent"></span>
            </div>
            
            <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-4 sm:mb-6 px-2">
              <span className="bg-gradient-to-br from-white to-gray-400 bg-clip-text text-transparent">Our Featured</span>{' '}
              <span className="relative">
                <span className="bg-gradient-to-r from-purple-400 via-fuchsia-500 to-blue-500 bg-clip-text text-transparent">Client Projects</span>
                <span className="absolute -bottom-1 left-0 w-full h-1 bg-gradient-to-r from-purple-400 via-fuchsia-500 to-blue-500 rounded opacity-70"></span>
              </span>
            </h2>
            
            <p className="max-w-2xl mx-auto text-gray-400 leading-relaxed px-4 text-sm sm:text-base">
              We work with businesses of all sizes, bringing technical expertise and genuine enthusiasm 
              to every project. Our passion lies in bringing ideas to life through thoughtful design and development.
            </p>
          </motion.div>
          
          {/* Floating design elements - hidden on smallest screens */}
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 1 }}
            className="absolute top-0 left-0 w-full h-full pointer-events-none hidden sm:block"
          >
            <div className="absolute top-8 left-8 w-8 h-8 border border-purple-500/30 rounded-full"></div>
            <div className="absolute bottom-12 right-12 w-16 h-16 border border-blue-500/20 rounded-full"></div>
            <div className="absolute top-1/2 right-1/4 w-4 h-4 bg-purple-500/20 rounded-full"></div>
          </motion.div>
        </div>
        
        {/* Category Tabs - Scrollable on mobile */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="mb-8 sm:mb-12 flex justify-center"
        >
          <div className="overflow-x-auto pb-2 max-w-full">
            <div className="inline-flex bg-gray-900/80 backdrop-blur-sm p-1.5 rounded-xl border border-gray-800 shadow-lg min-w-max">
              {categories.map((category) => (
                <button
                  key={category}
                  onClick={() => setActiveCategory(category)}
                  className={`px-3 sm:px-5 py-2 text-xs sm:text-sm font-medium rounded-lg transition-all duration-300 whitespace-nowrap ${
                    activeCategory === category
                      ? 'bg-gradient-to-br from-purple-500 to-blue-600 text-white shadow-md shadow-purple-500/20'
                      : 'text-gray-400 hover:text-white hover:bg-gray-800'
                  }`}
                >
                  {category}
                </button>
              ))}
            </div>
          </div>
        </motion.div>
        
        {/* Featured project (first project) */}
        {filteredProjects.length > 0 && (
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.7 }}
            className="mb-8 sm:mb-12 group"
          >
            <div className="relative overflow-hidden rounded-xl sm:rounded-2xl border border-gray-800 bg-gray-900/40 backdrop-blur-sm">
              <div className="grid grid-cols-1 lg:grid-cols-5 gap-0">
                {/* Project image (spans 3 columns on large screens) */}
                <div className="lg:col-span-3 relative overflow-hidden h-56 sm:h-64 md:h-72 lg:min-h-96">
                  <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-70 z-10"></div>
                  <div className="absolute inset-0 bg-gradient-to-r from-black to-transparent opacity-70 z-10 lg:opacity-0"></div>
                  <img
                    src={filteredProjects[0].image}
                    alt={filteredProjects[0].title}
                    className="w-full h-full object-cover object-center transform transition-transform duration-700 group-hover:scale-105"
                  />
                  
                  {/* Mobile-only title overlay */}
                  <div className="absolute bottom-0 left-0 p-4 sm:p-6 z-20 lg:hidden">
                    <span className="inline-block px-2 sm:px-3 py-1 bg-purple-600 text-white text-xs font-medium rounded-full mb-2 sm:mb-3">
                      {filteredProjects[0].category}
                    </span>
                    <h3 className="text-xl sm:text-2xl font-bold text-white mb-2">
                      {filteredProjects[0].title}
                    </h3>
                  </div>
                </div>
                
                {/* Project details (spans 2 columns on large screens) */}
                <div className="lg:col-span-2 p-4 sm:p-6 lg:p-8 flex flex-col justify-between">
                  <div>
                    {/* Only show on large screens */}
                    <div className="hidden lg:block">
                      <span className="inline-block px-3 py-1 bg-purple-600 text-white text-xs font-medium rounded-full mb-3">
                        {filteredProjects[0].category}
                      </span>
                      <h3 className="text-2xl font-bold text-white mb-4">
                        {filteredProjects[0].title}
                      </h3>
                    </div>
                    
                    <p className="text-gray-400 text-sm sm:text-base mb-4 sm:mb-6">
                      {filteredProjects[0].description}
                    </p>
                    
                    <div className="flex flex-wrap gap-2 mb-6 sm:mb-8">
                      {filteredProjects[0].tags.map(tag => (
                        <span key={tag} className="px-2 sm:px-3 py-1 sm:py-1.5 bg-gray-800 text-gray-300 text-xs font-medium rounded-full flex items-center gap-1.5">
                          {getTagIcon(tag)}
                          {tag}
                        </span>
                      ))}
                    </div>
                  </div>
                  
                  <a
                    href={filteredProjects[0].link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center gap-2 text-white bg-gradient-to-r from-purple-600 to-blue-600 hover:from-purple-700 hover:to-blue-700 px-4 sm:px-6 py-2 sm:py-3 rounded-full text-sm sm:text-base font-medium transition-all duration-300 shadow-lg shadow-purple-600/20"
                  >
                    View Project <ExternalLink size={16} className="ml-1" />
                  </a>
                </div>
              </div>
            </div>
          </motion.div>
        )}
        
        {/* Remaining projects in a modern masonry grid */}
        <div ref={projectsRef} className="relative">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
            {filteredProjects.slice(1).map((project, index) => (
              <motion.div
                key={project.title}
                initial={{ opacity: 0, y: 20 }}
                animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
                transition={{ 
                  duration: 0.5, 
                  delay: index * 0.1,
                }}
                whileHover={{ y: -4, scale: 1.01 }}
                onHoverStart={() => setHoveredProject(index)}
                onHoverEnd={() => setHoveredProject(null)}
                className="group flex flex-col bg-gray-900/40 backdrop-blur-sm border border-gray-800 rounded-lg sm:rounded-xl overflow-hidden h-full"
              >
                {/* Project image with overlay */}
                <div className="relative w-full h-40 sm:h-48 md:h-52 overflow-hidden">
                  <div className={`absolute inset-0 bg-gradient-to-t from-gray-900 to-transparent z-10 transition-opacity duration-500 ${hoveredProject === index ? 'opacity-40' : 'opacity-70'}`}></div>
                  <img
                    src={project.image}
                    alt={project.title}
                    className="w-full h-full object-cover object-center transition-transform duration-700 group-hover:scale-110"
                  />
                </div>
                
                {/* Content container */}
                <div className="flex-1 flex flex-col p-4 sm:p-6">
                  <div className="flex-1">
                    <div className="flex justify-between items-start mb-2 sm:mb-3">
                      <span className="inline-block px-2 sm:px-3 py-1 bg-gray-800/80 text-purple-400 text-xs font-medium rounded-full">
                        {project.category}
                      </span>
                    </div>
                    
                    <h3 className="text-lg sm:text-xl font-bold text-white mb-2 sm:mb-3">
                      {project.title}
                    </h3>
                    
                    <p className={`text-gray-400 text-xs sm:text-sm mb-3 sm:mb-4 transition-all duration-300 ${
                      expandedProject === index ? '' : 'line-clamp-2 sm:line-clamp-3'
                    }`}>
                      {project.description}
                    </p>
                    
                    {/* Read more toggle */}
                    <button
                      onClick={() => setExpandedProject(expandedProject === index ? null : index)}
                      className="inline-flex items-center text-purple-400 hover:text-purple-300 text-xs sm:text-sm mb-3 sm:mb-4"
                    >
                      {expandedProject === index ? 'Read less' : 'Read more'}
                      <ChevronDown
                        size={14}
                        className={`ml-1 transition-transform duration-300 ${
                          expandedProject === index ? 'rotate-180' : ''
                        }`}
                      />
                    </button>
                    
                    <div className="flex flex-wrap gap-1.5 sm:gap-2 mb-4">
                      {project.tags.map(tag => (
                        <span key={tag} className="px-2 py-1 bg-gray-800/50 text-gray-400 text-xs font-medium rounded-full">
                          {tag}
                        </span>
                      ))}
                    </div>
                  </div>
                  
                  <a
                    href={project.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center gap-1.5 text-white bg-gradient-to-r from-purple-600/90 to-blue-600/90 hover:from-purple-600 hover:to-blue-600 px-3 sm:px-4 py-1.5 sm:py-2 rounded-lg text-xs sm:text-sm font-medium transition-all duration-300 mt-auto"
                  >
                    View Project <ExternalLink className="h-3 w-3 sm:h-4 sm:w-4" />
                  </a>
                </div>
                
                {/* Animated border */}
                <div className="absolute inset-0 rounded-lg sm:rounded-xl border border-purple-500/0 group-hover:border-purple-500/30 transition-all duration-500 pointer-events-none"></div>
              </motion.div>
            ))}
          </div>
        </div>
        
        {/* Contact CTA */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7, delay: 0.2 }}
          className="mt-12 sm:mt-16 md:mt-24 text-center"
        >
          <div className="relative py-8 sm:py-12 md:py-16 px-4 sm:px-6 rounded-xl sm:rounded-3xl overflow-hidden">
            {/* Background gradient */}
            <div className="absolute inset-0 bg-gradient-to-br from-purple-900/20 via-gray-900/90 to-blue-900/20 backdrop-blur-sm"></div>
            <div className="absolute inset-0 border border-gray-700/30 rounded-xl sm:rounded-3xl"></div>
            
            {/* Radial patterns - hidden on smallest screens */}
            <div className="absolute top-0 right-0 w-48 sm:w-64 h-48 sm:h-64 bg-purple-500/10 rounded-full filter blur-2xl sm:blur-3xl -translate-y-1/2 translate-x-1/2 hidden sm:block"></div>
            <div className="absolute bottom-0 left-0 w-48 sm:w-64 h-48 sm:h-64 bg-blue-500/10 rounded-full filter blur-2xl sm:blur-3xl translate-y-1/2 -translate-x-1/2 hidden sm:block"></div>
            
            <div className="relative">
              <h3 className="text-2xl sm:text-3xl font-bold text-white mb-3 sm:mb-4">Ready to transform your ideas into reality?</h3>
              <p className="text-gray-300 mb-6 sm:mb-8 max-w-xl mx-auto text-sm sm:text-base">
                Whether you're a startup finding your feet or an established business reaching for new heights,
                we're here to elevate your digital presence.
              </p>
              <a 
                href="#contact" 
                className="inline-flex items-center gap-2 px-6 sm:px-8 py-3 sm:py-4 bg-white text-gray-900 text-sm sm:text-base font-semibold rounded-full hover:bg-gray-100 transition-all duration-300 shadow-lg"
              >
                Let's collaborate
                <ArrowUpRight className="h-4 w-4 sm:h-5 sm:w-5" />
              </a>
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default Works;
import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import Works from '../components/works';
import Team from '../components/team';
import AboutUs from '../components/aboutus';
import WhyUs from '../components/whyus';
import Services from '../components/services';
import ContactUs from '../components/contact';

function Home() {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://assets.calendly.com/assets/external/widget.js";
        script.async = true;
        document.body.appendChild(script);
    }, []);

    return (
        <>
            <section className="relative min-h-screen flex items-center justify-center bg-black overflow-hidden">
                {/* Background Elements */}
                <div className="absolute inset-0">
                    <div className="absolute inset-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#4f4f4f2e_1px,transparent_1px)] bg-[size:14px_24px]"></div>
                    <div className="absolute top-0 left-1/2 -translate-x-1/2 w-[500px] h-[500px] bg-gradient-to-r from-purple-500/30 to-blue-500/30 rounded-full blur-3xl opacity-20"></div>
                </div>

                <div className="relative z-10 max-w-7xl mx-auto px-6 py-32">
                    <div className="flex flex-col items-center text-center space-y-12">
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.6 }}
                            className="inline-flex items-center gap-2 px-4 py-2 rounded-full bg-gradient-to-r from-purple-500/10 to-blue-500/10 border border-purple-500/20"
                        >
                            <span className="relative flex h-2 w-2">
                                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-purple-400 opacity-75"></span>
                                <span className="relative inline-flex rounded-full h-2 w-2 bg-purple-500"></span>
                            </span>
                            <span className="text-purple-200 text-sm font-medium">Available for Projects</span>
                        </motion.div>

                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.6, delay: 0.2 }}
                            className="space-y-6"
                        >
                            <h1 className="text-5xl md:text-7xl font-bold leading-tight">
                                <span className="bg-gradient-to-r from-purple-400 via-pink-400 to-blue-400 bg-clip-text text-transparent">
                                    Crafting Digital
                                </span>
                                <br />
                                <span className="text-white">Experiences</span>
                            </h1>
                            
                            <p className="text-gray-400 text-lg md:text-xl max-w-2xl mx-auto leading-relaxed">
                                We transform ideas into exceptional digital products through strategic design and development.
                            </p>
                        </motion.div>
                    </div>
                </div>
            </section>

            {/* Other Sections */}
            <Works />
            <Services />
            <WhyUs />
            <AboutUs />
            <Team />

            {/* Contact Section with Calendly */}
            <section id="workwithus" className="relative py-32 bg-black overflow-hidden">
                <div className="max-w-7xl mx-auto px-6 relative text-center">
                    <h2 className="text-4xl md:text-5xl font-bold text-white mb-8">
                        Book a <span className="bg-gradient-to-r from-purple-400 to-blue-400 bg-clip-text text-transparent">Meeting</span>
                    </h2>
                    <p className="text-gray-400 text-lg max-w-2xl mx-auto mb-6">
                        Schedule a time to discuss your next project with us!
                    </p>
                    <div className="calendly-inline-widget" data-url="https://calendly.com/craftora-work/30min?background_color=701fe0&text_color=ffffff" style={{ minWidth: '320px', height: '700px' }}></div>
                </div>
                <ContactUs />
            </section>
        </>
    );
}

export default Home;

import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Logo from '../logo/craftora.png';
import { Link } from 'react-router-dom';

function Navbar() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => setIsScrolled(window.scrollY > 20);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Helper for smooth scrolling to an element by ID
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Define menu items. Items with a scrollId will use smooth scrolling.
  const menuItems = [
    { label: 'Home', route: '/' },
    { label: 'About Us', scrollId: 'aboutus' },
    { label: 'Projects', scrollId: 'projects' },
    { label: 'Contact', scrollId: 'contact' },
    { label: 'Our Services', scrollId: 'service' },
  ];

  return (
    <motion.header
      initial={{ y: 0 }}
      animate={{ y: 0 }}
      className={`fixed w-full z-50 transition-all duration-300 ${
        isScrolled ? 'bg-transparent backdrop-blur-sm border-b border-white/10' : 'bg-transparent'
      }`}
    >
      <nav className="max-w-7xl mx-auto px-6 py-4">
        <div className="flex items-center justify-between">
          {/* Logo */}
          <Link to="/" className="flex items-center space-x-2">
            <img src={Logo} alt="Craftora" className="h-10 w-10" />
            <span className="text-white font-bold text-xl">Craftora</span>
          </Link>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-8">
            {menuItems.map((item) =>
              item.scrollId ? (
                <button
                  key={item.label}
                  onClick={() => scrollToSection(item.scrollId)}
                  className="text-gray-300 hover:text-white transition-colors relative group"
                >
                  {item.label}
                  <span className="absolute -bottom-1 left-0 w-0 h-0.5 bg-gradient-to-r from-purple-500 to-blue-500 transition-all group-hover:w-full" />
                </button>
              ) : (
                <Link
                  key={item.label}
                  to={item.route}
                  className="text-gray-300 hover:text-white transition-colors relative group"
                >
                  {item.label}
                  <span className="absolute -bottom-1 left-0 w-0 h-0.5 bg-gradient-to-r from-purple-500 to-blue-500 transition-all group-hover:w-full" />
                </Link>
              )
            )}
          </div>

          {/* Desktop CTA Button: Work With Us */}
          <div className="hidden md:flex items-center space-x-4">
            <button
              onClick={() => scrollToSection('workwithus')}
              className="px-6 py-2 rounded-full bg-gradient-to-r from-purple-600 to-blue-600 text-white font-medium hover:shadow-lg hover:shadow-purple-500/25 transition-all duration-300"
            >
              Work with us
            </button>
          </div>

          {/* Mobile Menu Button */}
          <button
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            className="md:hidden text-white p-2"
          >
            <div className="w-6 h-5 relative flex flex-col justify-between">
              <span
                className={`w-full h-0.5 bg-white transform transition-all duration-300 ${
                  isMobileMenuOpen ? 'rotate-45 translate-y-2' : ''
                }`}
              />
              <span
                className={`w-full h-0.5 bg-white transition-all duration-300 ${
                  isMobileMenuOpen ? 'opacity-0' : ''
                }`}
              />
              <span
                className={`w-full h-0.5 bg-white transform transition-all duration-300 ${
                  isMobileMenuOpen ? '-rotate-45 -translate-y-2' : ''
                }`}
              />
            </div>
          </button>
        </div>

        {/* Mobile Menu */}
        <motion.div
          initial={false}
          animate={{ height: isMobileMenuOpen ? 'auto' : 0 }}
          className="md:hidden overflow-hidden"
        >
          <div className="py-4 space-y-4">
            {menuItems.map((item) =>
              item.scrollId ? (
                <button
                  key={item.label}
                  onClick={() => {
                    scrollToSection(item.scrollId);
                    setIsMobileMenuOpen(false);
                  }}
                  className="block text-gray-300 hover:text-white transition-colors px-4 py-2"
                >
                  {item.label}
                </button>
              ) : (
                <Link
                  key={item.label}
                  to={item.route}
                  className="block text-gray-300 hover:text-white transition-colors px-4 py-2"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  {item.label}
                </Link>
              )
            )}
            <button
              onClick={() => {
                scrollToSection('workwithus');
                setIsMobileMenuOpen(false);
              }}
              className="w-full px-6 py-2 rounded-full bg-gradient-to-r from-purple-600 to-blue-600 text-white font-medium hover:shadow-lg hover:shadow-purple-500/25 transition-all duration-300"
            >
              Work with us
            </button>
          </div>
        </motion.div>
      </nav>
    </motion.header>
  );
}

export default Navbar;

import React from "react";
import TeamPic1 from '../assets/team1.jpg';
import CraftoraPic from '../assets/craftora.jpeg';
import { motion } from 'framer-motion';

function AboutUs() {

    const awards = [
        {
            title: "KMC Hackathon 2024",
            place: "Grand Champion",
            project: "ApplyEaze : A.I HR System"
        }
    ];

    return (
        <>
            {/* About Us Section */}
            <section id="aboutus" className="relative py-24 md:py-32 bg-black overflow-hidden">
                {/* Background Elements */}
                <div className="absolute inset-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#4f4f4f2e_1px,transparent_1px)] bg-[size:14px_24px]"></div>
                <div className="absolute right-0 top-20 w-96 h-96 bg-purple-500/10 rounded-full filter blur-3xl"></div>
                <div className="absolute left-0 bottom-20 w-96 h-96 bg-blue-500/10 rounded-full filter blur-3xl"></div>

                <div className="relative max-w-7xl mx-auto px-6">
                    <div className="grid lg:grid-cols-2 gap-16 items-center">
                        {/* Left Column - Text Content */}
                        <motion.div 
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.6 }}
                            className="space-y-8"
                        >
                            <div className="inline-flex items-center px-3 py-1.5 rounded-full border border-purple-500/20 bg-purple-500/5">
                                <span className="relative flex h-2 w-2 mr-2">
                                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-purple-400 opacity-75"></span>
                                    <span className="relative inline-flex rounded-full h-2 w-2 bg-purple-500"></span>
                                </span>
                                <span className="text-sm text-purple-300 font-medium">About Our Journey</span>
                            </div>

                            <h2 className="text-4xl md:text-5xl font-bold leading-tight">
                                Crafting Digital 
                                <span className="block mt-2 bg-gradient-to-r from-purple-400 via-pink-400 to-blue-400 bg-clip-text text-transparent">
                                    Experiences That Matter
                                </span>
                            </h2>

                            <p className="text-gray-400 text-lg leading-relaxed">
                                We're a team of passionate creators and problem solvers, dedicated to transforming ideas into exceptional digital experiences. Our approach combines creativity with strategic thinking to deliver solutions that make an impact.
                            </p>
                        </motion.div>

                        {/* Right Column - Image */}
                        <motion.div
                            initial={{ opacity: 0, x: 20 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.8 }}
                            className="relative"
                        >
                            <div className="relative rounded-2xl overflow-hidden group">
                                <img 
                                    src={TeamPic1} 
                                    alt="Our Team" 
                                    className="w-full h-[500px] object-cover transform group-hover:scale-105 transition-transform duration-700"
                                />
                                <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/20 to-transparent"></div>
                            </div>
                        </motion.div>
                    </div>
                </div>
            </section>

            {/* Awards Section */}
            <section id="awards" className="relative py-24 md:py-32 bg-black overflow-hidden">
                {/* Background Elements */}
                <div className="absolute inset-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#4f4f4f2e_1px,transparent_1px)] bg-[size:14px_24px]"></div>
                <div className="absolute right-0 top-20 w-96 h-96 bg-blue-500/10 rounded-full filter blur-3xl"></div>
                <div className="absolute left-0 bottom-20 w-96 h-96 bg-purple-500/10 rounded-full filter blur-3xl"></div>

                <div className="relative max-w-7xl mx-auto px-6">
                    <div className="grid lg:grid-cols-2 gap-16 items-center">
                        {/* Left Column - Image */}
                        <motion.div
                            initial={{ opacity: 0, x: -20 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.8 }}
                            className="relative"
                        >
                            <div className="relative rounded-2xl overflow-hidden group">
                                <img 
                                    src={CraftoraPic} 
                                    alt="Award Event" 
                                    className="w-full h-[500px] object-cover transform group-hover:scale-105 transition-transform duration-700"
                                />
                                <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/20 to-transparent"></div>
                            </div>
                        </motion.div>

                        {/* Right Column - Awards */}
                        <motion.div 
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.6 }}
                            className="space-y-8"
                        >
                            <div className="inline-flex items-center px-3 py-1.5 rounded-full border border-blue-500/20 bg-blue-500/5">
                                <span className="relative flex h-2 w-2 mr-2">
                                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-400 opacity-75"></span>
                                    <span className="relative inline-flex rounded-full h-2 w-2 bg-blue-500"></span>
                                </span>
                                <span className="text-sm text-blue-300 font-medium">Our Achievements</span>
                            </div>

                            <h2 className="text-4xl md:text-5xl font-bold leading-tight">
                                Celebrating  
                                <span className="block mt-2 bg-gradient-to-r from-blue-400 via-purple-400 to-pink-400 bg-clip-text text-transparent">
                                    Excellence & Innovation
                                </span>
                            </h2>

                            {awards.map((award, index) => (
                                <motion.div
                                    key={index}
                                    initial={{ opacity: 0, y: 20 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    viewport={{ once: true }}
                                    transition={{ duration: 0.6, delay: index * 0.1 }}
                                    className="bg-gray-800 p-6 rounded-xl border border-gray-700 shadow-lg"
                                >
                                    <h3 className="text-xl font-semibold text-white">{award.title}</h3>
                                    <p className="text-gray-400">{award.place}</p>
                                    <p className="text-gray-500 text-sm">{award.project}</p>
                                </motion.div>
                            ))}
                        </motion.div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AboutUs;

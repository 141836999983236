import React from "react";
import { motion } from "framer-motion";
import Carl from '../assets/carl.jpg';
import Arjohn from '../assets/arjohn.jpg';
import Cayube from '../assets/cayube.png';
import Ally from '../assets/ally.jpg';

function Team() {
    const team = [
        {
            name: "Carl Saginsin",
            role: "Chief Technology Officer",
            image: Carl,
            bio: "Expert in Full Stack Development & AI Integration.",
            socials: {
                github: "https://github.com/CSaguinsin",
                linkedin: "https://www.linkedin.com/in/carl-saginsin-30358928a/",
                facebook: "https://www.facebook.com/carlsaginsin/"
            }
        },
        {
            name: "Arjohn Capucion",
            role: "Chief Operations Officer",
            image: Arjohn,
            bio: "Operations Specialist with a Focus on Process Optimization.",
            socials: {
                github: "https://github.com/arjohn405",
                linkedin: "https://www.linkedin.com/in/arjohn-capucion/",
                facebook: "https://www.facebook.com/arjohn.capucion"
            }
        },
        {
            name: "Adriana Cayube",
            role: "Chief Marketing Officer",
            image: Cayube,
            bio: "Digital Marketing Strategist & Brand Development Expert",
            socials: {
                github: "#",
                linkedin: "https://www.linkedin.com/in/aecayube/",
                facebook: "https://www.facebook.com/EmCayube"
            }
        },
        {   
            name: "Allyza Toquire",
            role: "Chief Executive Officer",
            image: Ally,
            bio: "Transformational Leader with a Vision for Sustainable Growth.",
            socials: {
                github: "https://github.com/allybit",
                linkedin: "https://www.linkedin.com/in/maria-allyza-toquire-83057921b/",
                facebook: "https://www.facebook.com/allyza.toquire"
            }
        }
    ];

    return (
        <section id="team" className="relative py-32 bg-black overflow-hidden">
            {/* Background Elements */}
            <div className="absolute inset-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#4f4f4f2e_1px,transparent_1px)] bg-[size:14px_24px]"></div>
            <div className="absolute right-0 top-20 w-96 h-96 bg-purple-500/10 rounded-full filter blur-3xl"></div>
            
            <div className="max-w-7xl mx-auto px-6 relative">
                {/* Header */}
                <motion.div 
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    className="text-center mb-20"
                >
                    <span className="inline-block px-4 py-1.5 rounded-full bg-gradient-to-r from-purple-500/10 to-blue-500/10 border border-purple-500/20 text-purple-300 text-sm mb-4">
                        Meet Our Team
                    </span>
                    <h2 className="text-4xl md:text-5xl font-bold text-white mb-6">
                        The <span className="bg-gradient-to-r from-purple-400 to-blue-400 bg-clip-text text-transparent">Minds</span> Behind
                        <br /> Our Innovation
                    </h2>
                    <p className="text-gray-400 text-lg max-w-2xl mx-auto">
                        A team of passionate developers and designers creating exceptional digital experiences.
                    </p>
                </motion.div>

                {/* Team Grid */}
                <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-12">
                    {team.map((member, index) => (
                        <motion.div
                            key={member.name}
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ delay: index * 0.1 }}
                            className="group relative"
                        >
                            {/* Profile Image */}
                            <div className="relative mb-6">
                                <div className="aspect-[4/5] overflow-hidden rounded-2xl">
                                    <img
                                        src={member.image}
                                        alt={member.name}
                                        className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-500"
                                    />
                                </div>
                                {/* Gradient Overlay */}
                                <div className="absolute inset-0 bg-gradient-to-t from-black/80 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-2xl"></div>
                                
                                {/* Social Links - Appear on Hover */}
                        
<div className="absolute bottom-4 left-0 right-0 flex justify-center gap-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
    {Object.entries(member.socials).map(([platform, link]) => (
        <a
            key={platform}
            href={link}
            className="w-8 h-8 flex items-center justify-center rounded-full bg-white/10 backdrop-blur-sm hover:bg-white/20 transition-colors"
            target="_blank"
            rel="noopener noreferrer"
        >
            <span className="sr-only">{platform}</span>
            {platform === 'github' && (
                <svg className="w-4 h-4 text-white" fill="currentColor" viewBox="0 0 24 24">
                    <path fillRule="evenodd" d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z" clipRule="evenodd" />
                </svg>
            )}
            {platform === 'linkedin' && (
                <svg className="w-4 h-4 text-white" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/>
                </svg>
            )}
            {platform === 'facebook' && (
                <svg className="w-4 h-4 text-white" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"/>
                </svg>
            )}
        </a>
    ))}
</div>
                            </div>

                            {/* Text Content */}
                            <div className="text-center">
                                <h3 className="text-xl font-bold text-white mb-1">{member.name}</h3>
                                <p className="text-purple-400 font-medium mb-3">{member.role}</p>
                                <p className="text-gray-400 text-sm">
                                    {member.bio}
                                </p>
                            </div>
                        </motion.div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Team;